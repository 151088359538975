<template>
  <div class="page">
    
    <div class="login-overlay">
      <div class="login-box">
        <div class="image"><fa-icon icon="lock" /></div>
        <div class="login">
          <el-input placeholder="E-Mail" v-model="email"></el-input>
          <el-input placeholder="Password" v-model="password" show-password></el-input>
          <div v-if="errorInfo" class="error">{{ errorInfo.message }}</div>
          <el-button @click="performLogin" :loading="loading">Login</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/services/auth";
export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      error: null
    }
  },
  computed: {
    errorInfo() {
      if (!this.error) {
        return null;
      }
      switch (this.error.code) {
        case "auth/invalid-email":
          return {
            message: "Überprüfe deine E-Mail-Addresse",
          };

        case "auth/too-many-requests":
          return {
            message:
              "Zu viele Anmeldeversuche, bitte versuche es später erneut."
          };

        case "auth/network-request-failed":
          return {
            message:
              "Verbindung zum Server fehlgeschlagen. Überprüfe deine Internet Verbindung."
          };

        case "auth/user-not-found":
        case "auth/wrong-password":
          return {
            message: "Falsche E-Mail-Addresse oder Password",
          };

        default:
          return {
            message: "Unbekannter Fehler. Bitte versuche es später erneut."
          };
      }
    }
  },
  methods: {
    async performLogin() {
      try {
        this.error = null;
        this.loading = true;
        await auth.login(this.email, this.password);
        this.loading = false;
        this.email = "";
        this.password = "";
        this.$router.push("/");
      } catch (error) {
        this.loading = false;
        this.error = error;
      }
    },

    close() {
      this.error = null;
      this.password = "";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.login-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);

  .login-box {
    width: 550px;
    height: 300px;
    box-sizing: border-box;
    display: flex;
    background: white;
    margin: calc(40vh - 150px) auto 0 auto;
    border-radius: 18px 12px 12px 18px;
    box-shadow: 2px 4px 22px rgba(0, 0, 0, 0.7);

    .image {
      width: 200px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 84px;
      background: $deepsea;
      color: darken($deepsea, 4%);
      border-radius: 12px 0 0 12px;
    }

    .login {
      padding: 70px 60px 30px 60px;

      .error {
        font-size: 12px;
        color: $racing;
        text-align: center;
        margin-bottom: 20px;
      }

      .el-input {
        margin: 0 0 20px 0;
      }
    }
  }
}
</style>
